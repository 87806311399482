.loading {
  font-family: 'Manrope';
  color: var(--white);
  line-height: 33px;
  font-weight: 400;
  font-size: 14.5px;
  padding-inline: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message {
  text-align: center;
  max-width: 690px;
  margin-top: 25px;
}

@media screen and (min-width: 768px) {
  .loading {
    font-size: 20px;
  }
}
