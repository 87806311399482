.container {
  padding: 25px 0 0 30px;
  box-shadow: 0px 30px 40px 0px rgba(7, 109, 243, 0.05);
  border-bottom: 0.5px solid transparent;
  background: linear-gradient(to right, var(--white), var(--white)), linear-gradient(to right, var(--linear-gradient-one), var(--linear-gradient-two));
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.mobile {
  margin: 5.7rem 0 24px;
}

.mobileAlt {
  margin: 0 0 24px;
}

.bigscreen {
  display: none;
  margin: 5rem 0 48px;
}

.bigscreenAlt {
  margin: 0rem 0 48px;
}

.backarrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  width: 20%;
}

.backarrowimg {
  width: 10px;
}

.backarrowtext {
  font-size: 16px;
  font-weight: 400;
  font-family: var(--font-family-manrope);
}

.circletext {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.step {
  font-size: 20px;
  font-weight: 500;
  font-family: var(--font-family-manrope);
  margin-top: 5px;
}

.label {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--font-family-manrope);
  margin-top: 4px;
}

@media screen and (min-width: 1024px) {
  .bigscreen {
    display: block;
  }

  .mobile {
    display: none;
  }
}