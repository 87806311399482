@import './styles/base.css';
@import './styles/buttons.css';
@import './styles/colors.css';
@import './styles/headers.css';
@import './styles/icons.css';

.app-main {
  max-width: 1280px;
  margin: 0 auto;
}

main {
  margin-top: 84px;
}

.circles-img {
  position: absolute;
  height: 240px;
  z-index: -1;
}

.paragraph-text {
  font-size: 1.1rem;
}

@media screen and (min-width: 560px) {
  .circles-img {
    position: absolute;
    height: 440px;
    z-index: -1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
