.wrapper {
  width: 100%;
  background: var(--primary);
  padding: 20px 0;
}

.headerContainer {
  border-bottom: 1px solid #febd1c;
  height: 37px;
}

.headerContainer h1 {
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 600;
  letter-spacing: 5%;
  color: white;
}

.image {
  margin: 30px 0;
  width: 100%;
  height: 107px;
  border-radius: 32px;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 32px;
}

.description h1 {
  font-size: 18px;
  line-height: 24px;
  font-family: 'Poppins';
  width: 100%;
  color: white;
}

.description p {
  font-size: 14px;
  line-height: 16px;
  font-family: 'Manrope';
  width: 100%;
  color: #ebebeb;
}

.numbers {
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: space-between;
  height: 179px;
  gap: 20px;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.right {
  width: 200px;
}

.box {
  height: 70px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.box h2 {
  font-family: 'DM Sans';
  font-size: 33px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.box p {
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin: 80px 0;
}

.viewBtn {
  background: white;
}

@media only screen and (min-width: 650px) {
  .headerContainer {
    height: 57px;
  }

  .headerContainer h1 {
    font-size: 36px;
    line-height: 30.52px;
  }

  .image {
    margin: 80px 0;
    height: 385px;
  }

  .description h1 {
    font-size: 40px;
    line-height: 48px;
  }

  .description p {
    font-size: 20px;
    line-height: 30px;
  }

  .numbers {
    height: 357px;
    max-width: 686px;
    padding: 20px;
  }

  .right {
    width: 360px;
  }

  .box {
    height: 120px;
  }

  .box h2 {
    font-size: 60px;
    line-height: 30px;
  }

  .box p {
    font-size: 24px;
  }

  .btnContainer {
    flex-direction: row;
  }
}

@media only screen and (min-width: 1100px) {
  .wrapper {
    padding: 80px 50px 30px;
  }

  .contentWrapper {
    flex-direction: row;
  }

  .description {
    gap: 48px;
    max-width: 608px;
  }

  .description h1 {
    max-width: 558px;
  }

  .description p {
    max-width: 508px;
  }

  .numbers {
    max-width: 686px;
  }
}

@media only screen and (min-width: 1300px) {
  .wrapper {
    padding: 80px 40px 50px;
  }
}

@media only screen and (min-width: 1390px) {
  .wrapper {
    padding: 80px 90px 30px;
  }
}
