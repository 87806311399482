.content {
  font-family: 'Manrope';
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emailexist {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 1rem;
}

.emailexisttext {
  font-weight: 600;
  font-size: 20px;
}

.logintext {
  margin-bottom: 20px;
}

.emailexisttext,
.logintext {
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .emailexist {
    width: 540px;
  }

  .logintext {
    font-size: 1.1rem;
    margin: 0px 80px 20px;
  }
}
