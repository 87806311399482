.step1 {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.heading {
  font-size: 28px;
  max-width: 240px;
  line-height: 38px;
  color: var(--edustipend-black);
}

.select {
  max-width: 550px;
  display: flex;
  margin-bottom: 43px;
  gap: 28px;
}

.selectCategory {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.btn {
  max-width: 275px;
  color: var(--edustipend-black);
}

.quoteSection {
  display: flex;
  justify-content: center;
}

.quote {
  text-align: center;
  color: var(--edustipend-black);
  font-size: 14px;
  padding: 24px 0;
  max-width: 200px;
  font-weight: 300;
  font-style: italic;
}

@media screen and (min-width: 426px) {
  .heading {
    max-width: 444px;
  }

  .select {
    gap: 0;
  }

  .buttons {
    align-items: flex-end;
  }
}

@media screen and (max-width: 350px) {
  .quote {
    font-size: 12px;
  }
}

@media screen and (min-width: 600px) {
  .quote {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .heading {
    font-size: 36px;
    line-height: 45px;
  }

  .buttons {
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
  }

  .select {
    margin-bottom: 123px;
  }

  .quote {
    font-size: 16px;
    padding: 60px 0;
  }
}
