.ImpactNumbers {
  flex: 1 1 calc(100% - 24px);
  box-sizing: border-box;
}

.ImpactNumbers {
  width: 100%;
  height: 192px;
  border-radius: 8px;
  background-color: var(--edustipend-purple);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ImpactNumbers h2 {
  font-size: 40px;
  color: var(--edustipend-yellow);
}

.ImpactNumbers p {
  font-size: 16px;
  color: var(--white);
  margin-top: 24px;
}

@media (min-width: 600px) {
  .ImpactNumbers {
    flex: 1 1 calc(50% - 24px);
  }
}

@media (min-width: 900px) {
  .ImpactNumbers {
    flex: 1 1 calc(33.333% - 24px);
  }
}

@media only screen and (min-width: 640px) {
  .ImpactNumbers {
    width: 250px;
  }
}
@media only screen and (min-width: 640px) {
  .ImpactNumbers {
    width: 250px;
  }
}
@media only screen and (min-width: 1024px) {
  .ImpactNumbersWrap {
    gap: 20px;
  }
  .ImpactNumbers {
    height: 322px;
    width: 250px;
  }

  .ImpactNumbers h2 {
    font-size: 60px;
  }

  .ImpactNumbers p {
    font-size: 24px;
  }
}
