.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  padding: 25px;
  overflow-x: hidden;
}

.note {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.btnContainer {
  display: flex;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
  font-family: 'Manrope', sans-serif;
}

.prev {
  color: var(--edustipend-black);
  border: 0.406px solid rgba(45, 55, 64, 0.5);
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.prev:hover {
  transform: scale(1.03);
}

.next {
  color: var(--edustipend-black);
  color: #333;
  background-color: var(--edustipend-yellow);
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.next:hover {
  transform: scale(1.03);
}

.effect {
  width: max-content;
  padding: 8px 10px;
  border-radius: 3em;
  background: rgba(255, 255, 255, 0.09);
  box-shadow: 0px 14px 13px -4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.btn {
  width: 130px;
}

@media only screen and (min-width: 888px) {
  .container {
    display: none;
  }
}
