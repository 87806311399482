.footer-wrapper {
  display: flex;
  /* box-shadow: 0px 3px 5px #888, 0px -3px 5px #888; */
  padding-top: 0.5rem;
  margin-top: 1rem;
  position: relative;
  color: white;
}

.footer-wrapper:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: -1rem;
  width: 100%;
  height: calc(100% + 1rem);
  /* opacity: 0.85; */
  background-image: url('/src/assets/footer.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(90, 34, 159, 0.5);
  z-index: -2;
}

.footer-container {
  display: flex;
  flex-direction: column;
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
}

.footer-container > div:first-child {
  margin-bottom: 2rem;
}

.footer-icon > a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.footer-icon > a > .logo-text {
  margin: 0;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 500;
}

.footer-copyright {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid darkgray;
  padding: 0.5rem 1rem;
  margin-top: 0.5rem;
}

.footer-follow-us {
  display: none;
}

.footer-icons {
  display: flex;
  flex-direction: column;
  padding: 0.5rem.5rem;
}

.footer-social-icons {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  padding-left: 8px;
}

.footer-social-icons > * {
  margin-right: 8px;
}

.footer-icons-container {
  display: flex;
  padding: 1rem;
  flex-direction: column;
}

.footer-top {
  display: flex;
  padding: 1.5rem;
}

.footer-top > div {
  width: 50%;
}

.footer-link {
  color: white;
  display: block;
  text-decoration: none;
  margin: 1rem 0;
}

.footer-link:hover {
  text-decoration: underline;
}

@media screen and (min-width: 560px) {
  .display-card {
    height: 220px;
    min-width: 220px;
    padding: 1rem;
  }

  .logo-text {
    display: flex;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    margin: auto;
    font-weight: bold;
  }

  .footer-follow-us {
    display: flex;
  }

  .footer-icons-container {
    display: flex;
    padding: 1rem;
    flex-direction: row;
  }
}
