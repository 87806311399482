.banner {
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  top: 84px;
  padding: 0 1.5rem;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.banner--alert {
  background-color: var(--edustipend-turquioise);
  color: var(--edustipend-black);
}

.banner--info {
  background-color: var(--light-gray);
  color: var(--edustipend-black);
}

.banner--error {
  background-color: var(--red-accent);
  color: white;
}

@media screen and (min-width: 1351px) {
  .banner {
    top: 102px;
  }
}
