.main {
  position: relative;
  font-family: 'Poppins';
}

.clockImg {
  width: 100%;
  display: flex;
  align-self: baseline;
}

.requestContent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  margin-bottom: 26px;
  max-width: 820px;
  color: var(--navy-blue);
  font-size: 32px;
  line-height: 35px;
  position: relative;
}

.rocket {
  position: absolute;
  width: 39px;
  height: 39px;
  top: -4rem;
}

.blob {
  position: absolute;
}

.blobLeft {
  left: 0;
  top: 55%;
  width: 20px;
  height: 250px;
}

.blobRight {
  right: 0;
  top: 10%;
  width: 25px;
  height: 300px;
}

@media screen and (min-width: 768px) {
  .requestContent {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
  }

  .rocket {
    position: relative;
    top: -15rem;
  }

  .title {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .blobLeft {
    top: 30%;
    width: initial;
    height: initial;
  }

  .blobRight {
    top: -7%;
    width: initial;
    height: initial;
  }
}

@media screen and (min-width: 1440px) {
  .main {
    padding-top: 50px;
  }

  .rocket {
    width: initial;
    height: initial;
    top: -12.5rem;
    right: -10.5rem;
  }

  .title {
    font-size: 60px;
    line-height: 70px;
    margin-bottom: 64px;
  }

  .requestContent {
    top: 6.5rem;
  }
}
