.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    padding: 36px;
    border-radius: 14px;
    max-width: 500px;
    margin-inline: 14px;
    gap: 16px;
}

.img {
    width: 80px;
    height: 80px;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5;
}

.message {
    font-family: var(--font-family-manrope);
    font-size: 24px;
    text-align: center;
    font-weight: 500;
}

.prompt {
    font-family: var(--font-family-manrope);
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    margin-top: .8rem;
    font-style: italic;
}

.btnContainer {
    display: flex;
    justify-content: center;
}


@media screen and (max-width:375px) {
    .main {
        max-width: 300px;
    }
}

@media screen and (max-width:500px) {

    .img {
        width: 50px;
        height: 50px;
    }

    .message {
        font-size: 18px;
    }

    .prompt {
        font-size: 14px;
    }

    .btn {
        width: 250px;
    }
}