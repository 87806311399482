.background {
  background: url('../../assets/hero-bg.png');
  background-size: cover;
}

.main {
  padding-top: 7.5rem;
  margin-inline: 18px;
  max-width: 1440px;
  padding-bottom: 2.5rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 23px;
  justify-content: center;
}

.left {
  position: relative;
  width: 100%;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.right {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.aisha {
  object-fit: cover;
  width: 100%;
  height: 524px;
}

.quote {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-manrope);
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(7.300000190734863px);
  padding: 16px 15px 30px 21px;
  line-height: 19px;
  color: var(--light-black);
  font-size: 18px;
  font-weight: 500;
}

.aishaQuote {
  max-width: 607px;
  font-size: 13px;
  font-weight: 500;
}

.message {
  position: relative;
  display: flex;
  flex-direction: column;
}

.name {
  align-self: flex-end;
  color: var(--black);
  font-weight: 700;
  font-size: 14px;
}

.quoteLeft {
  position: absolute;
  left: -1rem;
  top: -0.8rem;
  width: 18px;
  height: 18px;
}

.quoteRight {
  position: absolute;
  right: 0rem;
  bottom: 1.2rem;
  width: 18px;
  height: 18px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 30px;
  background: var(--white);
  box-shadow: 0px 30px 40px 0px rgba(7, 109, 243, 0.05);
  padding: 25.86px 16px;
}

.heading {
  font-weight: 600;
  font-family: var(--font-family-manrope);
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  color: var(--edustipend-black);
}

.title {
  font-size: 20px;
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  max-width: 493px;
  color: var(--navy-blue);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.toggleContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
}

.phoneContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.toggle {
  position: relative;
  width: 32px;
  height: 19.2px;
  background: var(--gray);
  border-radius: 43.2px;
  cursor: pointer;
  transition: all 300ms linear;
}

.disabled {
  opacity: 0.1;
}

.toggleAlt {
  background-color: var(--edustipend-purple);
  transition: all 300ms linear;
}

.ball {
  position: absolute;
  left: 3px;
  top: 1.9px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--white);
}

.ballAlt {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 1.9px;
  right: 3px;
  border-radius: 50%;
  background: var(--white);
}

.fullname {
  opacity: 0.5;
}

.anon {
  font-size: 16px;
}

.btnContainer {
  width: 100%;
  margin-top: 10px;
  padding: 0 0.5rem;
}

.btn {
  width: 100%;
  padding: 28px 0;
  font-size: 18px;
}

.effect {
  width: 100%;
}

.footnote {
  padding: 10px 0;
  text-align: center;
  font-style: italic;
  font-size: 16px;
}

.phoneInfo {
  position: relative;
  margin-top: 7.8px;
  cursor: pointer;
}

.info {
  font-family: var(--font-family-manrope);
  display: none;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  width: max-content;
  background-color: var(--edustipend-purple);
  padding: 20px 16px;
  border-radius: 18px;
  top: -8.2rem;
  left: -100px;
}

.infoArrow {
  position: absolute;
  bottom: -12px;
  left: 100px;
}

.titleInfo {
  font-size: 15px;
  color: var(--white);
  font-weight: 500;
}

.subtitleInfo {
  font-size: 14px;
  max-width: 220px;
  margin-top: 8px;
  color: var(--white);
  line-height: 16.5px;
  font-weight: 400;
}

.small {
  width: 100%;
  align-self: center;
  text-align: center;
  font-size: 18px;
  color: var(--red-accent);
  font-weight: 500;
}

.smallAlt {
  position: absolute;
  text-align: initial;
  bottom: -1.5rem;
  left: 0;
}

.img:hover ~ .info {
  display: block;
}

.infoFocus {
  display: block;
}

.hidden {
  display: none;
}

.animText {
  margin-top: 3px;
  font-family: var(--font-family-manrope);
  color: var(--edustipend-purple);
  font-size: 14px;
}

.animTextAlt {
  font-family: var(--font-family-manrope);
  font-size: 14px;
  margin-top: 3px;
  color: var(--edustipend-purple);
  animation: anim 700ms ease-in-out forwards;
}

@keyframes anim {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 500px) {
  .left {
    display: none;
  }

  .small {
    font-size: 16px;
  }

  .animText,
  .animTextAlt {
    font-size: 14px;
  }
}

@media screen and (min-width: 425px) {
  .info {
    top: -7.2rem;
  }

  .subtitleInfo {
    max-width: 285px;
  }
}

@media screen and (min-width: 600px) {
  .info {
    left: -45px;
  }

  .infoArrow {
    left: 45px;
  }

  .aishaQuote {
    font-size: 16px;
    line-height: 23px;
  }

  .title {
    font-size: 32px;
  }

  .subtitle {
    font-size: 16px;
  }
}

@media screen and (min-width: 768px) {
  .aisha {
    height: 100%;
  }

  .content {
    flex-direction: row;
  }

  .main {
    padding-top: 8.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .content {
    gap: 72px;
  }

  .left,
  .right {
    width: 50%;
  }

  .quoteLeft {
    width: 30px;
    height: 30px;
    left: -1.8rem;
    top: -1.5rem;
  }

  .quoteRight {
    width: 30px;
    height: 30px;
  }

  .formContainer {
    padding: 32px 40px;
  }

  .name {
    font-size: 18px;
  }

  .quote {
    padding: 32px 26px 60px 37px;
  }

  .aishaQuote {
    font-size: 18px;
    line-height: 34px;
  }
}

@media screen and (min-width: 1350px) {
  .main {
    margin-inline: auto;
  }
}

@media screen and (min-width: 1440px) {
  .main {
    padding-inline: auto;
  }
}
