.videoContainer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 320px;
}

.iframe,
.video {
  width: 100%;
  border: 1px solid var(--edustipend-white);
  border-radius: 4px;
}

@media only screen and (min-width: 768px) {
  .videoContainer {
    height: 414px;
    margin-top: 50px;
  }

  .iframe,
  .video {
    height: 414px;
  }
}

@media only screen and (min-width: 1024px) {
  .videoContainer {
    height: 514px;
    margin-top: 60px;
  }

  .iframe,
  .video {
    height: 514px;
  }
}

@media only screen and (min-width: 1440px) {
  .videoContainer {
    height: 614px;
  }

  .iframe,
  .video {
    height: 614px;
  }
}
