.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

.info {
    font-family: var(--font-family-manrope);
    color: var(--white);
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

@media screen and (width < 400px) {
    .info{
        font-size: 16px;
        line-height: 25px;
    }
}