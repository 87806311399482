.container {
  padding: 120px 0;
  width: 100%;
  max-width: 1030px;
  margin: 0 auto;
  background: #f9fafb;
}
.topSection {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
}
.goals {
  width: 100%;
  max-width: 471px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  border-radius: 16px;
}

.top {
  width: 100%;
  display: flex;
  gap: 16px;
}

.bottom {
  width: 100%;
  padding: 32px 16px 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 8px 0px 0px 0px;
  box-shadow: 0px 0px 6px -2px #1018280d;
  border: 1px solid #e4e7ec;
  background: #ffffff;
}

.progress {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.progressTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.progressTop h1 {
  font-family: 'Manrope';
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: var(--edustipend-black);
}

.progressTop h1 span {
  opacity: 0.8;
}

.progressTop p {
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--edustipend-black);
}

.progressContainer {
  display: flex;
  flex-direction: column;
}
.completion {
  align-self: self-end;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
}

.progressBar {
  width: 100%;
  height: 16px;
  background-color: #febd1c33;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}

.progressFill {
  height: 100%;
  background-color: var(--edustipend-purple);
  border-radius: 16px 0 0 16px;
}

.link {
  width: 100%;
}

.link div,
.link button {
  width: 100%;
}

.donationsFilter {
  width: 100%;
  max-width: 472px;
  min-height: 196px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 0px 6px -2px #1018280d;
  background: white;
}

.filterTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterTop h1 {
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #101828;
}

.donatedAmount {
  font-family: 'Poppins';
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #101828;
}

.select {
  width: 109px;
  height: 44px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  font-size: 16px;
  color: '#101828';
  border-radius: 8px;
  background-color: #fff;
  appearance: none;
  background-image: url('./../../assets/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 16px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
}

.select option {
  background-color: #fff;
  color: #1e2022;
  padding: 20px;
}

.select:focus {
  outline: none;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
}

@media (min-width: 380px) {
  .progressTop h1 {
    font-size: 24px;
    line-height: 20px;
  }
}

@media (min-width: 768px) {
  .container {
    padding: 150px 0;
    background: white;
  }
  .goals,
  .top {
    gap: 32px;
  }
  .topSection {
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 53px;
  }
}
