.install {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-bottom: 10px;
  margin-right: 10px;
}

.btn {
  width: 130px;
}
