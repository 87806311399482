.ambassador-image {
  width: 200px;
  margin-left: 10px;
}

.page-subheader {
  margin-top: 20px;
  font-family: 'Manrope', sans-serif;
  text-align: left;
}

.about-main-child {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.subheader {
  width: 70%;
  margin: 0 auto;
}

/* ABOUT */
.about-main-child:nth-of-type(1) {
  order: 2;
}

.about-main-child:nth-of-type(2) {
  order: 1;
  padding: 1rem;
}

/* AMBS IMPACTS */
.abs-name {
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}

.absimage {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.vertical-text {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-weight: bolder;
  font-size: 24px;
}

.project-photos-and-arrow img {
  display: none;
}

.project-photos {
  color: var(--primary);
  font-weight: bold;
  font-size: 14px;
}

.project-photos-and-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
  gap: 10px;
  cursor: pointer;
}

.data-stipend {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.data-stipend-text {
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal {
  position: relative;
  padding: 20px;
  max-width: 80%;
  position: relative;
}

.prevArrowNav {
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  height: 50px;
  cursor: pointer;
}

.nextArrowNav {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  cursor: pointer;
  height: 50px;
}

.close-button {
  position: absolute;
  top: -30px;
  right: -200px;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 20px;
  color: #fff;
}

.abs-main {
  padding: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(250, 235, 215, 0.45);
  max-width: 1260px;
  margin: 1rem auto;
  min-height: 540px;
  align-items: center;
}

.ambsimpacts {
  text-align: center;
  display: flex;
  justify-content: center;
  min-height: 540px;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}

.header-abs {
  background-image: linear-gradient(to right, #febd1c, #5801ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.benefits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* BENEFITS SECTION */
.benefit-main-child {
  width: 100%;
}

.benefits-main-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

/* ELIGIBILITY SECTION  */
.eligibility-header-text {
  margin-bottom: 10px;
}

.eligibility-header-text,
.eligibility-header-list,
.about-header-text,
.requirement-header-text {
  margin-top: 20px;
  font-family: 'Manrope', sans-serif;
  text-align: left;
  font-size: 14px;
}

.eligibility-header-list {
  margin-bottom: 0px;
}

.eligibility-main-image {
  margin-top: 40px;
}

.top-main {
  max-width: 1260px;
  margin: 0 auto;
  min-height: 540px;
  display: flex;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
  text-align: center;
}

/* REQUIREMENTS SECTION */

/* TOP/HERO SECTION  */
.abs-top-main-star-div {
  display: none;
}

.top-section-header {
  font-size: 40px;
}

.top-section-header-text {
  font-size: 14px;
  width: 95%;
  margin: 10px auto 0px;
  text-align: center;
}

.abs-top-main-child {
  position: relative;
  height: 320px;
  margin: auto;
  margin-top: 1rem;
  width: 1440px;
  display: grid;
  place-items: center;
  overflow: hidden;
  transition: all 300ms linear;
  width: 100%;
  overflow: hidden;
}

.ambassador-image-wrapper {
  display: flex;
  width: calc(286px * 8);
  animation: scroll 40s linear infinite;
}

.slide {
  width: 286px;
  height: 320px;
  display: flex;
  align-items: center;
  padding-right: 30px;
}

.img {
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.bigscreen {
  display: none;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
    transition: all 1s ease-in-out;
  }

  100% {
    transform: translateX(calc(-286px * 4));
    transition: all 1s ease-in-out;
  }
}

/* AMBASSADOR */
.text {
  font-family: 'Manrope', sans-serif;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-top: 40px;
}

.section-header-underline {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-text {
  font-weight: bolder;
}

.abs-section-header {
  font-size: 24px;
  margin-bottom: 20px;
}

.bigscreen-about-img {
  display: none;
}

.bottom-main {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.image-banner {
  display: flex;
  flex-direction: row;
  padding: 1rem;
}

.image-desktop-only {
  display: none;
}

.about-main-header {
  display: none;
  background-color: #eee6ff;
  text-align: center;
  max-width: 1220px;
  margin: 0 auto;
}

.abs-desc {
  font-size: 14px;
  margin-top: 20px;
  font-family: 'Manrope', sans-serif;
  text-align: left;
}

.section-header-line {
  margin-bottom: 30px;
  margin-top: 20px;
}

.about-main-main-child {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  background-color: #eee6ff;
  max-width: 1220px;
  margin: 1rem auto;
  min-height: 540px;
  align-items: center;
  gap: 10px;
}

.requirement-main {
  text-align: center;
  /* display: flex; */
  justify-content: center;
  flex-direction: column-reverse;
  background-color: #fff;
  max-width: 1260px;
  margin: 1rem auto;
  min-height: 540px;
  align-items: center;
  gap: 10px;
}

/** Apply Now CTA **/
.apply-now {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 2rem 2rem;
  gap: 1rem;
}

.btn-NavHashLink {
  display: none;
}

.apply-now > button {
  margin-top: 1rem;
}

/** REQUEST SECTION **/
.request-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem 1rem;
}

.top-cta-btn {
  margin-top: 2rem;
}

.top-cta-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.top-cta-container > .btn:nth-of-type(2) {
  margin-top: 1rem;
}

.benefits-main {
  max-width: 1260px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
}

.benefits-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.benefits {
  padding: 24px;
  text-align: left;
  min-height: 200px;
  background-color: #f7f2ff;
  margin-top: 20px;
  border-radius: 8px;
}

.section-header-large {
  display: none;
}

.benefits-courses {
  padding: 24px;
  text-align: left;
  min-height: 200px;
  background-color: #fffcf4;
  margin-top: 20px;
  border-radius: 8px;
}

.benefits-impact {
  padding: 24px;
  text-align: left;
  min-height: 200px;
  background-color: #fff7f5;
  margin-top: 20px;
  border-radius: 8px;
}

.benefits-data-icon-div {
  padding: 1rem;
  background-color: #e8daff;
  display: flex;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 11px;
}

.benefits-courses-div {
  padding: 1rem;
  background-color: #fff6df;
  display: flex;
  justify-content: center;
  width: 100px;
  border-radius: 11px;
}

.benefits-impact-div {
  padding: 1rem;
  background-color: #ffe8e2;
  display: flex;
  justify-content: center;
  width: 100px;
  border-radius: 11px;
}

.abs-top-main {
  position: relative;
  margin: 0 auto;
  min-height: 540px;
  display: flex;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
  text-align: center;
}

.abs-top-main-star {
  display: flex;
  position: absolute;
  top: 40px;
  right: 0px;
}

.eligibilty {
  padding-top: 1rem;
}

.application-form {
  max-width: 300px;
}

.request-form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  max-width: 500px;
}

@media screen and (min-width: 560px) {
  /* TOP/HERO SECTION */
  .ambassador-image-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .top-section-header {
    font-size: 48px;
  }

  .top-section-header-text {
    font-size: 18px;
  }

  .ambassador-image {
    width: 80%;
  }
}

@media screen and (min-width: 1024px) {
  .eligibility-header-text,
  .eligibility-header-list,
  .about-header-text,
  .requirement-header-text {
    font-size: 16px;
  }

  .eligibility {
    margin-top: 50px;
  }

  .section-header {
    display: flex;
  }

  .subheader {
    width: 80%;
    margin: 0;
  }

  .top-main-child {
    width: 50%;
    justify-content: center;
  }

  .image-desktop-only {
    display: flex;
    flex-direction: column;
  }

  .section-child {
    width: 50%;
  }

  .student-image {
    margin-left: 2rem;
    margin-right: 2rem;
    border-radius: 16px;
    border: 4px solid var(--primary);
  }

  /* TOP SECTION */
  .bigscreen {
    display: block;
  }

  .mobile {
    display: none;
  }

  .top-section-header-text {
    font-size: 24px;
    width: 70%;
    margin: 0;
  }

  /* ABOUT SECTION */
  .about-main-child-header {
    display: none;
  }

  .about-main-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
  }

  .about-main-main-child {
    flex-direction: row;
    margin: 0 auto;
    padding: 20px;
  }

  .about-main {
    flex-direction: row;
  }

  .about-main-child:nth-of-type(1) {
    order: 1;
  }

  /* BENEFITS SECTION */
  .benefits-main {
    padding: 0;
    margin-bottom: 100px;
    margin-top: 60px;
  }

  .benefits-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .benefits,
  .benefits-courses,
  .benefits-impact {
    width: 350px;
  }

  .section-text {
    font-size: 24px;
  }

  .top-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 70px;
    margin-bottom: 20px;
  }

  .about-main-child:nth-of-type(2) {
    text-align: left;
    margin-right: 2rem;
    width: 50%;
    order: 2;
    padding: unset;
  }

  /* ELIGIBILITY SECTION */
  .top-main-eligibility {
    min-height: 560px;
    display: flex;
    flex-direction: row;
    text-align: left;
    gap: 40px;
    margin-top: 40px;
  }

  .top-main-eligibility-child {
    width: 50%;
  }

  .eligibility-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    margin-top: 40px;
  }

  .eligibility-main-image {
    width: 100%;
    margin-top: 0;
  }

  .data-stipend-text {
    margin-top: 15px;
    font-size: 14px;
    text-align: left;
  }

  .top-main-child-eligibility {
    justify-content: center;
  }

  .top-main {
    min-height: 560px;
    display: flex;
    text-align: left;
    gap: 20px;
  }

  /* REQUEST SECTION */

  .top-cta-container {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    justify-content: unset;
    align-items: unset;
  }

  .top-cta-container > .btn:nth-of-type(1) {
    margin-right: 1rem;
  }

  .top-cta-container > .btn:nth-of-type(2) {
    margin-top: 0;
  }

  .btn-NavHashLink {
    display: flex;
  }

  /* REQUIREMENTS SECTION */
  .req-section-header {
    text-align: left;
    margin-bottom: 20px;
  }

  .requirement-main {
    text-align: center;
    display: block;
    flex-direction: column-reverse;
    background-color: #fff;
    max-width: 1260px;
    margin: 1rem auto;
    min-height: 300px;
    align-items: center;
    gap: 10px;
  }

  .requirement-main-child {
    padding: 0;
  }

  /* AMBS IMPACTS SECTION */
  .project-photos {
    font-size: 18px;
  }

  .ambsimpacts {
    flex-direction: row;
    gap: 50px;
    padding: 1rem;
  }

  .abs-name {
    font-size: 28px;
  }

  .abs-top-main-child {
    width: 100%;
    overflow: hidden;
  }

  .benefits {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .ambassador-image {
    width: 100%;
  }

  .req-section-header {
    text-align: left;
  }

  .section-header {
    display: none;
  }

  .section-header {
    display: flex;
  }

  .abs-top-main-star-div {
    display: flex;
  }

  .absimage-div {
    width: 350px;
  }

  .absimages {
    width: 100%;
  }

  .section-header-line {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .section-header-large {
    display: flex;
    width: 80%;
    margin-top: 40px;
  }

  .section-header-small {
    display: none;
  }

  .abs-top-main {
    max-width: 1300px;
    min-height: 560px;
    padding: 2rem 3rem;
    flex-direction: column;
    text-align: left;
  }

  .request-form-container {
    width: 100%;
    max-width: 1200px;
  }

  .project-photos-and-arrow img {
    display: block;
  }

  .impact-photos {
    height: 500px !important;
  }

  .abs-desc {
    font-size: 16px;
  }

  .vertical-text {
    font-size: 28px;
  }
}
