.referPage {
  background: rgba(247, 247, 247, 1);
}

.donationNetwork {
  padding-top: 200px;

  display: flex;
  justify-content: center;
}

.referFormSection {
  width: 100%;
  max-width: 1057px;
  margin: 95px auto 0;
}

.referHeader {
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}

.referParagraph {
  text-align: center;
  font-size: 20px;
  color: rgba(0, 0, 0, 1);

  margin-bottom: 64px;
}

.referForm {
  background: rgba(255, 255, 255, 1);
  padding: 40px 32px;
}

.referFormButton {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.referralText {
  margin-top: 32px;
  margin-bottom: 14px;
  font-size: 18px;
  font-weight: 500;
}

.referralLink {
  width: 100%;
  height: 65px;
  padding: 22px;
  gap: 0px;
  border-radius: 15px;
  border: 0.5px solid rgba(45, 55, 64, 0.2);
  color: rgba(45, 55, 64, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.genLink {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.referralLinkCopy {
  font-weight: 600;
  font-size: 18px;
  color: rgba(88, 1, 255, 1);
  cursor: pointer;
}

.copySuccess {
  color: green;
  font-size: 0.9rem;
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .donationNetwork {
    padding-top: 70px;
  }

  .referFormSection {
    margin-top: 95px;
  }

  .referForm {
    padding: 40px 16px;
  }

  .header {
    font-size: 32px;
    font-weight: 500;
    line-height: 35.2px;
  }
}

/* 717 */
@media screen and (max-width: 717px) {
  .donationNetwork {
    display: none;
  }

  .referHeader {
    padding-top: 48px;
  }

  .referFormSection {
    margin-top: 35px;
  }

  .referParagraph {
    margin-bottom: 30px;
  }
}

/* 537 */
@media screen and (max-width: 537px) {
  .referHeader {
    margin-bottom: 24px;
  }

  .referParagraph {
    font-size: 18px;
    text-align: justify;
  }

  .referralLinkCopy {
    font-size: 14px;
  }
}
