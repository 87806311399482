.submit {
  position: relative;
  background-color: var(--white);
  max-width: 854px;
  border-radius: 30px;
  padding: 55px 12px;
}

.submitAlt {
  padding-inline: 0;
}

.arrow {
  position: absolute;
  top: 1rem;
  left: 0.2rem;
  width: 55px;
  height: 55px;
  padding: 1rem;
  opacity: 0.7;
}

.arrow:hover {
  cursor: pointer;
}

.headerContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.emoji {
  width: 47px;
  height: 47px;
}

.emojiAlt {
  width: 56px;
  height: 56px;
}

.header {
  font-size: 24px;
  text-align: center;
  max-width: 500px;
  margin-top: 15px;
  line-height: 28px;
}

.header2 {
  font-size: 20px;
}

.bottomSection {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prompt {
  text-align: center;
  font-size: 16px;
  font-family: 'Manrope';
  max-width: 435px;
  font-weight: 300;
}

.btnContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-direction: column;
  margin-top: 24px;
}

.btn {
  max-width: 270px;
  color: var(--edustipend-black);
}

.Quote {
  text-align: center;
  font-style: italic;
  padding: 14px 0 0;
  font-size: 14px;
  font-weight: 400;
}

.userEmail {
  font-weight: 700;
  padding: 0.3rem 0;
}

@media screen and (max-width: 350px) {
  .header {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .submit {
    padding-inline: 100px;
    /* height: 559px; */
  }

  .submitAlt {
    padding-inline: 30px;
  }

  .emoji {
    width: 55px;
    height: 55px;
  }

  .emojiAlt {
    width: 97px;
    height: 97px;
  }

  .prompt {
    font-size: 18px;
  }

  .header {
    font-size: 36px;
    line-height: 40px;
  }

  .bottomSection {
    margin-top: 30px;
  }

  .arrow {
    top: 2rem;
    left: 2rem;
    width: 60px;
    height: 60px;
  }

  .Quote {
    padding: 50px 0 12px;
  }

  .btnContainer {
    flex-direction: row-reverse;
    margin-top: 62px;
  }

  .btnContainerAlt {
    margin-top: 82px;
  }

  .btnContainerAltt {
    margin-top: 200px;
    justify-content: center;
  }

  .alt {
    padding-top: 60px;
  }
}
