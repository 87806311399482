.container {
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
}

.img {
  width: 200px;
  margin-top: 20px;
}

.header {
  color: var(--edustipend-black);
  text-align: center;
  font-size: 18px;
  font-family: poppins;
  margin-top: 10px;
  font-weight: 500;
}

.text {
  font-size: 12px;
  font-family: poppins;
  text-align: center;
  margin-top: 10px;
  width: 70%;
}

@media screen and (min-width: 1024px) {
  .main {
    width: 520px;
  }

  .img {
    width: 70%;
  }

  .header {
    font-size: 32px;
  }

  .text {
    font-size: 16px;
    width: 95%;
  }
}
