.target {
  width: 100%;
  min-height: 125px;
  padding: 16px;
  gap: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 6px -2px #1018280d;
  border: 1px solid #e4e7ec;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  font-family: 'Manrope';
}

.icon {
  align-self: flex-end;
  width: max-content;
  height: 24px;
}

.desc {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.value {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #2d3740;
}

.category {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #475467;
  text-transform: capitalize;
}

@media (min-width: 380px) {
  .value {
    font-size: 24px;
  }
}
