.consent {
  position: relative;
  background-color: var(--white);
  padding: 55px 10px;
  border-radius: 30px;
  max-width: max-content;
  height: 460px;
}

.arrow {
  position: absolute;
  top: 1rem;
  left: 0.2rem;
  width: 55px;
  height: 55px;
  padding: 1rem;
  opacity: 0.7;
}

.arrow:hover {
  cursor: pointer;
}

.declined {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 15px;
}

.emoji {
  width: 27px;
  height: 27px;
}

.header {
  font-size: 24px;
  font-weight: 500;
  max-width: 220px;
  line-height: 24px;
}

.prompt {
  font-size: 16px;
  font-family: 'Manrope';
  font-weight: 300;
  max-width: 220px;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.btn {
  color: var(--edustipend-black);
}

@media screen and (min-width: 425px) {
  .consent {
    padding-inline: 24px;
  }
}

@media screen and (min-width: 768px) {
  .consent {
    padding: 84px 70px;
    width: 854px;
    height: 559px;
  }

  .header {
    max-width: 440px;
    font-size: 36px;
    line-height: 36px;
  }

  .prompt {
    font-size: 18px;
    max-width: 370px;
  }

  .arrow {
    top: 2rem;
    left: 2rem;
    width: 60px;
    height: 60px;
  }

  .emoji {
    width: 40px;
    height: 40px;
    margin-bottom: 1.5rem;
  }

  .btnContainer {
    flex-direction: row-reverse;
    padding-top: 4rem;
  }

  .btnContainer2 {
    padding-top: 7.5rem;
  }
}

@media screen and (min-width: 1440px) {
  .consent {
    padding-inline: 165px;
  }
}
