.bg {
  background-image: url('../../../assets//hero-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 40px;
  padding: 30px 10px;
  margin-top: 80px;
}

.cta {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quotes,
.cta {
  width: 100%;
}

.benimagediv {
  width: 100%;
  height: 450px;
}

.benimage {
  width: 100%;
  height: 100%;
}

.header,
.headerweb {
  text-align: center;
  color: var(--edustipend-purple);
  z-index: 1;
}

.headerweb {
  display: none;
}

.header {
  display: block;
  line-height: 1.2;
}

.text {
  text-align: center;
  padding: 0 10px;
  margin-top: 1rem;
}

.ctabtns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.quotes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.quote {
  font-size: 12px;
  padding: 0 5px;
}

.quoteU {
  display: flex;
  align-self: flex-start;
  width: 15px;
  height: 15px;
}

.quoteD {
  display: flex;
  align-self: flex-end;
  width: 15px;
  height: 15px;
}

.quotecont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: none;
  position: absolute;
  bottom: 0;
  background-color: white;
  opacity: 0.6;
  padding: 15px 10px;
}

.quotes .quotecont {
  display: block;
  transition: 0.5s ease-in-out;
  margin-top: 40px;
}

.quot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font: 12px;
}

.ben {
  display: flex;
  align-self: flex-end;
}

.benName {
  font-size: 12px;
  font-weight: 900;
}

.when {
  font-size: 10px;
}

.fade {
  animation-name: fadeOut;
}

.fadeIn {
  animation-name: fadeIn;
}

.benwhen {
  padding: 0 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (min-width: 640px) {
  .text {
    margin: 0px 100px;
  }

  .quotecont {
    padding: 35px 60px;
  }

  .benimagediv {
    padding: 0 50px;
    height: 600px;
  }
}

@media only screen and (min-width: 768px) {
  .benwhen {
    padding: 0 12px;
  }

  .ctabtns {
    flex-direction: row;
    width: 100%;
    gap: 50px;
  }

  .quote {
    font-size: 18px;
    padding: 0 15px;
  }

  .ben {
    margin-top: 10px;
  }

  .benName {
    font-size: 18px;
  }

  .when {
    font-size: 14px;
  }

  .quotecont {
    padding: 35px 60px;
  }
}

@media only screen and (min-width: 1024px) {
  .benwhen {
    padding: 0 30px;
  }

  .benimage {
    width: 100%;
  }

  .text {
    font-size: 22px;
    margin: 0px 10px;
  }

  .benimagediv {
    padding: 0 50px;
    height: 600px;
  }

  .quote {
    font-size: 18px;
    padding: 0 30px;
  }

  .quotecont {
    padding: 35px 60px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    flex-direction: row-reverse;
    padding: 80px 50px 80px;
    gap: 60px;
    align-items: center;
  }

  .benimagediv {
    padding: 0 0px;
    height: 600px;
  }

  .quotecont {
    padding: 20px 10px;
  }

  .quotes,
  .cta {
    width: 50%;
  }

  .cta {
    gap: 30px;
  }

  .header {
    text-align: center;
    margin: 0px 100px;
    display: none;
  }

  .headerweb {
    display: block;
    line-height: 1.25;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    margin-top: 100px;
    padding: 80px 0px 80px;
  }
}
