.main {
  margin-bottom: 100px;
  overflow-x: hidden;
}

.frame {
  width: 100%;
  height: 35px;
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
}

.section {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 22px;
}

.con {
  display: flex;
  justify-content: center;
}

.headerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub_header {
  width: 320px;
}

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 54px;
  color: #2d3740;
}

.subTitle {
  font-size: 18px;
  font-weight: 500;
  color: #2d3740;
  max-width: 320px;
}

.ctaButtonContainer {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 350px) {
  .sub_header {
    width: 100%;
  }

  .subTitle {
    font-size: 14px;
  }
}

@media screen and (min-width: 550px) {
  .subTitle {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .sub_header {
    width: 100%;
  }

  .subTitle {
    margin-top: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .btns {
    display: block;
  }

  .preBtn {
    left: 0;
  }

  .nextBtn {
    right: 0;
  }

  .frame {
    height: 55px;
    margin-bottom: 18px;
  }
}

@media screen and (min-width: 1440px) {
  .main {
    background: url(/src/assets/ghedu.png);
    background-size: contain;
  }

  .section {
    margin-bottom: 51px;
  }

  .title {
    font-size: 64px;
    line-height: 84px;
  }

  .subTitle {
    font-size: 23px;
  }
}
