.main {
  height: 100vh;
  position: fixed;
  margin-top: 80px;
  width: max-content;
  background: var(--white);
  z-index: 2;
  transition: all 1s;
  transform: translateX(0);
}

.container {
  width: 230px;
  min-height: 100vh;
}

.sidecolor {
  height: 50px;
  width: 7px;
  background-color: var(--edustipend-purple);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.quotecontainer,
.sidebarlist {
  padding: 30px 20px;
}

.line {
  height: 1px;
  width: 100%;
  background-color: var(--light-gray);
  margin-top: 20px;
}

.sidebarlist_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  margin-top: 10px;
  border-radius: 10px;
  gap: 10px;
}

.active {
  background-color: var(--light-gray);
}

.inactive {
  padding-left: 17px;
}

.sidebaricontitle {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  margin-left: 10px;
}

.sidebartitle {
  font-weight: 500;
  font-size: 16px;
  font-family: 'Manrope';
  color: var(--edustipend-black);
}

.active .sidebartitle {
  color: var(--edustipend-purple);
  font-weight: bold;
}

.active .sidebaricon {
  color: var(--edustipend-purple);
}

.quote {
  background-color: var(--light-gray);
  font-size: 14px;
  text-align: center;
  padding: 0px 20px 20px;
  border-radius: 10px;
}

.quotemain {
  background-color: var(--light-gray);
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  text-align: center;
  padding-top: 10px;
  border-radius: 10px;
}

.quotecontainer {
  /* height: 280px; */
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: gray lightgray;
}

.header {
  font-weight: bold;
}

.main.hideSidebar {
  transform: translateX(-105vw);
  min-height: 100vh;
  width: 200px;
}

@media screen and (min-width: 1024px) {
  .container {
    width: 254px;
  }

  .quote {
    font-size: 16px;
    padding: 0px 28px 20px;
  }

  .quotecontainer {
    height: 380px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: gray lightgray;
    overflow-x: hidden;
  }

  .quotecontainer::-webkit-scrollbar {
    width: 0.5px;
  }

  .quotecontainer::-webkit-scrollbar-track {
    background-color: lightgray;
  }

  .quotecontainer::-webkit-scrollbar-thumb {
    background-color: gray;
  }

  .quotemain {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
  }

  .sidebartitle {
    font-size: 18px;
  }
}
