.how-it-works-container {
  padding: 80px 0px 100px 0px;
  background-image: url('../../assets/howitworksbackground.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.how-it-works-header {
  text-align: center;
  font-size: 32px;
}

h3.how-it-works-subheader {
  font-size: 18px;
  text-align: center;
}

.card-wrapper {
  position: relative;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rrrr-transparent-card {
  background-color: #ffffff;
  width: 100%;
  height: 300px;
  border-radius: 30px;
  opacity: 0.1;
  box-shadow: rgba(0, 0, 0, 0.9) 0px 3px 8px;
}

.rrrr-card {
  background-color: #ffffff;
  width: 92%;
  height: 300px;
  margin: -50px auto;
  border-radius: 30px;
  position: absolute;
  top: 28px;
  z-index: 900;
}

.rrrr-svg-header {
  margin: 30px 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.rrrr-header {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  color: #2d3740;
}

.rrrr-header-text {
  margin: 5px 20px 30px;
  font-family: 'Manrope';
  line-height: 25px;
  text-align: left;
  font-size: 14px;
  color: #2d3740;
}

@media screen and (min-width: 375px) {
  .rrrr-transparent-card {
    height: 280px;
  }

  .rrrr-card {
    height: 280px;
  }
}

@media screen and (min-width: 400px) {
  .rrrr-transparent-card {
    width: 100%;
    height: 240px;
  }

  .rrrr-card {
    width: 92%;
    height: 240px;
  }
}

@media screen and (min-width: 600px) {
  .card-main-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin: 40px 0 0px;
  }

  .rrrr-transparent-card {
    width: 100%;
    height: 280px;
  }

  .rrrr-card {
    width: 90%;
    height: 320px;
    margin: -90px 12px;
  }
}

@media screen and (min-width: 768px) {
  .rrrr-transparent-card {
    width: 100%;
    height: 250px;
  }

  .rrrr-card {
    width: 90%;
    height: 280px;
    margin: -90px 12px;
  }
}

@media screen and (min-width: 800px) {
  .card-main-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 80px 50px 0px;
    padding-bottom: 80px;
  }

  .rrrr-header-text {
    margin: 0px 15px 30px;
    font-family: 'Manrope';
    line-height: 25px;
    text-align: left;
    margin-top: 10px;
    font-size: 18px;
  }

  .rrrr-svg-header {
    margin: 30px 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }

  .rrrr-transparent-card {
    width: 100%;
    height: 270px;
  }

  .rrrr-card {
    width: 90%;
    height: 300px;
  }
}

@media screen and (min-width: 1024px) {
  .rrrr-transparent-card {
    height: 220px;
  }

  .rrrr-card {
    height: 250px;
  }
}

@media screen and (min-width: 1300px) {
  .card-main-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
  }

  .rrrr-transparent-card {
    height: 430px;
    width: 100%;
  }

  .rrrr-card {
    height: 460px;
    width: 85%;
    padding-top: 5px;
  }

  .rrrr-header-text {
    line-height: 28px;
  }
}

@media screen and (min-width: 1440px) {
  h3.how-it-works-header {
    font-size: 64px;
  }

  h3.how-it-works-subheader {
    font-size: 32px;
  }
}
