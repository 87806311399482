.container {
  background: #4109ae;
  width: 100%;
  height: calc(100vh - 83px);
  max-height: 1003px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.backdrop {
  display: flex;
  width: 100%;
  max-width: 1200px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow:
    0px 8px 6px 0px rgba(0, 0, 0, 0.05),
    0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset,
    0px -1px 1px 0px rgba(255, 255, 255, 0.25) inset,
    2px 3px 3px -3px rgba(255, 255, 255, 0.6) inset;
  backdrop-filter: blur(50px);
}

.welcome {
  width: 100%;
  height: 100%;
  max-height: 682px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 30px 40px 0px rgba(7, 109, 243, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.img {
  margin-top: 50px;
}

.headText {
  color: var(--edustipend-text-black, #2d3740);
  text-align: center;
  font-family: 'Poppins';
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 136.5%; /* 65.52px */
}

.textContainer {
  width: 541px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(45, 55, 64, 0.05);
}

.subText {
  color: var(--edustipend-text-black, #2d3740);
  font-family: 'Manrope';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
}

.desc {
  width: 550px;
  color: var(--edustipend-text-black, #2d3740);
  font-family: 'Manrope';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
}

.desc ul {
  max-width: 439px;
  margin-left: 30px;
}
.desc ul li {
  list-style-type: disc;
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-bottom: 33px;
  padding-right: 20px;
}

@media only screen and (max-width: 1024px) {
  .backdrop {
    padding: 15px 9px;
  }
  .welcome {
    padding: 0 10px;
  }

  .img {
    width: 49px;
    height: 49px;
  }
  .headText {
    font-size: 32px;
    line-height: 100%; /* 32px */
  }

  .textContainer {
    width: 100%;
    max-width: 500px;
  }

  .subText {
    font-size: 16px;
    text-align: center;
  }

  .desc {
    font-size: 16px;
    width: 100%;
    max-width: 500px;
    padding: 0 10px;
  }
}

@media only screen and (max-width: 760px) {
  .btnContainer {
    justify-content: center;
    padding-right: 0px;
  }
}
