.main {
  background: url('../../assets/hero-bg.png');
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

.contentContainer {
  margin-inline: 1.5rem;
  max-width: 700px;
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.content {
  background: var(--white);
  border-radius: 30px;
  width: 100%;
  margin-top: 2.5rem;
  padding: 30px 20px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  font-size: 24px;
}

.text {
  text-align: center;
  max-width: 525px;
}

.img {
  width: 45px;
  height: 45px;
}

.footNote {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.inputConatiner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputContent {
  width: 500px;
}

.btn {
  max-width: 280px;
}

.Modal {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  gap: 1rem;
  padding: 2rem 1rem;
}

.feedBack {
  text-align: center;
  max-width: 400px;
  font-size: 18px;
  font-weight: 600;
}

.btnContainer {
  margin-top: 1rem;
}

.modalBody {
  font-size: 1.125rem;
  text-align: center;
}

.imgAlt {
  width: 40px;
  height: 40px;
}

.btn {
  width: 240px;
}

@media screen and (min-width: 375px) {
  .btn {
    width: 248px;
  }

  .content {
    padding: 38px 30px;
  }
}

@media screen and (min-width: 425px) {
  .btn {
    width: 310px;
  }
}

@media screen and (max-width: 320px) {
  .header {
    font-size: 14px;
  }

  .text {
    font-size: 14px;
  }

  .btn {
    width: 200px;
  }
}

@media screen and (min-width: 856px) {
  .contentContainer {
    margin-inline: auto;
  }

  .content {
    margin-top: 5.5rem;
    gap: 2rem;
  }

  .img {
    width: 60px;
    height: 60px;
  }

  .imgAlt {
    width: 60px;
    height: 60px;
  }

  .btn {
    max-width: 100%;
  }

  .header {
    font-size: 36px;
  }

  .text {
    font-size: 20px;
  }

  .Modal {
    gap: 2rem;
    padding: 3rem;
  }

  .feedBack {
    font-size: 24px;
  }
}
