.mobileTable {
  border-collapse: collapse;
  font-size: 0.95em;
  width: 100%;
  margin: 0;
  border-radius: 30px;
  font-family: 'Manrope';
  box-shadow: 0px 9px 38px 0px var(--box-shadow);
}

.mobileTable thead th {
  text-align: left;
  font-size: 18px;
  border-bottom: 1px solid var(--line-alt);
}

.head {
  background-color: var(--table-head-bg);
  border-radius: 30px 0 0 0;
  font-weight: 600;
  width: 50%;
}

.headAlt {
  border-radius: 0;
}

.mobileTable tbody tr:last-child {
  border-bottom-right-radius: 30px;
}

.row {
  background-color: var(--white);
  border-radius: 0 30px 0 0;
  width: 50%;
}

.rowAlt {
  border-radius: 0;
}

.row1 {
  border-bottom-left-radius: 30px;
}

.row2 {
  border-bottom-right-radius: 30px;
}

.mobileTable th,
.mobileTable td {
  padding: 1rem;
}

.mobileTable tbody tr {
  border-bottom: 1px solid var(--line-alt);
}

.mobileTable tbody tr:last-child {
  border-bottom: 1px solid transparent;
}

.mobileTable td {
  padding: 1.5rem 1rem;
}

.entryNav {
  display: flex;
  align-items: center;
}

.id {
  width: 20px;
  font-size: 18px;
  margin-inline: 30px;
  text-align: center;
  border-bottom: 2px solid black;
}

.rightArrow,
.leftArrow {
  padding: 0;
  display: flex;
  background-color: transparent;
}

.leftArrow:disabled,
.rightArrow:disabled {
  opacity: 0.2;
}

.arrow_img {
  width: 15px;
  height: 15px;
}

.actionContainer {
  /* width: 106px; */
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  margin-top: -20px;
}

.line {
  width: 1px;
  height: 100%;
  background: #0000001a;
}

.view {
  all: unset;
  height: 100%;
  display: flex;
  align-items: center;
}
.view p {
  color: var(--Edustipend-Purple, #5801ff);
  font-family: 'Manrope';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.edit {
  all: unset;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.edit p {
  color: #039855;
  font-family: 'Manrope';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.edit:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.content {
  position: absolute;
  top: -80px;
  left: -50px;
  display: block;
  width: 150px;
  background: white;
  z-index: 2;
  color: red;
  padding: 4px;
  font-size: 14px;
  box-shadow: 0px 0px 10px 0px rgba(88, 0, 255, 0.2);
  font-weight: 900;
  text-align: center;
  opacity: 1 !important;
}
