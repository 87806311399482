button,
.btn {
  outline: none;
  height: 52px;
  border: 0;
  border-radius: 22px;
  font-size: 1.25rem;
  padding: 0 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary {
  color: white;
  background-color: var(--primary);
  border: 1.5px solid var(--primary);
}

.btn-primary:hover {
  background-color: var(--primaryHover);
  border: 1.5px solid var(--primaryHover);
}

.btn-universal {
  color: var(--primary);
  background-color: white;
  border: 1.5px solid var(--primary);
}

.btn-universal:hover {
  color: var(--primaryHover);
  border: 1.5px solid var(--primaryHover);
}
