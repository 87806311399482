.edustipend-button {
  position: relative;
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.1);
  width: 310px;
}

.disabled {
  opacity: 0.75;
}

.disabled .edustipend-button {
  cursor: not-allowed;
}

.right-arrow {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 30px;
}

.edustipend-button--primary {
  color: white;
  background-color: var(--edustipend-purple);
}

.edustipend-button--secondary {
  color: #333;
  background-color: var(--edustipend-yellow);
}

.edustipend-button--plain {
  background-color: transparent;
  border: 1px solid #d9d9d980;
  box-shadow: none;
}

.edustipend-button--small {
  font-size: 14px;
  padding: 10px 16px;
}

.edustipend-button--medium {
  font-size: 16px;
  padding: 11px 20px;
}

.edustipend-button--large {
  font-size: 18px;
  padding: 14px 30px;
}

.effect {
  width: max-content;
  padding: 8px 10px;
  border-radius: 3em;
  background: #ffffff40;
  box-shadow: 0px 14px 13px -4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.effectAlt {
  padding: 6px;
}

.effect:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.effect.disabled:hover {
  cursor: not-allowed;
  transform: scale(1);
}

.effect_alt {
  background: rgba(255, 255, 255, 0.09);
  backdrop-filter: blur(0.7px);
  -webkit-backdrop-filter: blur(0.7px);
  border-top: 2px solid rgba(255, 255, 255, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.icon-button {
  width: 308px;
  position: relative;
}

.icon {
  position: absolute;
  width: 9.38px;
  height: 18.75px;
  top: 34%;
  opacity: 0.8;
}

.back-icon {
  left: 3rem;
}

.front-icon {
  right: 3rem;
}

@media screen and (max-width: 350px) {
  .edustipend-button {
    width: 240px;
  }

  .effect {
    padding: 10px;
  }
}

@media screen and (min-width: 768px) {
  .edustipend-button {
    width: 248px;
  }

  .icon-button {
    width: 174px;
  }

  .back-icon {
    left: 30px;
  }

  .front-icon {
    right: 25px;
  }
}
