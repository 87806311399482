.small {
  width: 50px;
}

.medium {
  width: 100px;
}

.large {
  width: 175px;
}
