.main {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-family: 'Manrope';
  gap: 0.4rem;
}

.label {
  font-size: 16px;
  display: flex;
  align-items: center;
  color: var(--navy-blue);
  margin-left: 0.45rem;
}

.required {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 24px;
  color: var(--red-accent);
  font-weight: 700;
}

.defaultContainer {
  position: relative;
}

.select {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  border: 1px solid var(--darker-navy-blue);
  background-color: var(--white);
  padding: 0 19px;
  border-radius: 15px;
  outline: none;
  caret-color: transparent;
  color: var(--navy-blue);
  -webkit-border-radius: 15px;
  border-radius: 15px;
}

.select::placeholder {
  color: var(--darker-accent);
  font-size: 16px;
}

.select:hover {
  cursor: pointer;
}

.select:focus {
  border: 0.5px solid transparent;
  border-radius: 15px;
  background: linear-gradient(to right, white, white), linear-gradient(to right, #5801ff77, #00babb77);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.select:active {
  border: 0.5px solid transparent;
  border-radius: 15px;
  background: linear-gradient(to right, white, white), linear-gradient(to right, #5801ff77, #00babb77);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.default {
  color: var(--darker-accent);
}

.dropDown {
  background: transparent;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  top: 0;
  right: 0;
  height: 100%;
  pointer-events: none;
}

.dropDownArr {
  width: 17.7px;
  height: 10.5px;
  transition: all 300ms ease-in-out;
}

.dropDownAlt {
  transform: rotate(180deg);
}

.optionsContainer {
  position: absolute;
  width: 100%;
  max-height: 168px;
  overflow: auto;
  top: 4.5rem;
  border: 0.5px solid transparent;
  border-radius: 15px;
  color: var(--navy-blue);
  background: linear-gradient(to right, white, white), linear-gradient(to right, #5801ff77, #00babb77);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  scrollbar-width: 0;
  scrollbar-color: transparent;
  z-index: 1;
}

.optionsContainerAlt {
  scrollbar-width: 5px;
  scrollbar-color: linear-gradient(to bottom, #5801ff77, #00babb77);
}

.optionsContainer::-webkit-scrollbar {
  width: 0;
}

.optionsContainerAlt::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

.optionsContainerAlt::-webkit-scrollbar-track {
  border-radius: 10px;
  margin: 5px;
}

.optionsContainerAlt::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #5801ff44, #00babb44);
  border-radius: 10px;
}

.option {
  width: 100%;
  display: flex;
}

.option:hover,
option:active {
  background-color: lightyellow;
}

.itm {
  padding: 1rem;
  width: 100%;
  border-bottom: 1px solid var(--line);
  background-color: white;
}

.itm:hover {
  cursor: pointer;
  color: var(--primary);
}

.small {
  width: 15%;
}

.medium {
  width: 25%;
}

.large {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .label {
    font-size: 18px;
  }

  .select {
    font-size: 18px;
  }

  .select::placeholder {
    font-size: 18px;
  }
}
