.testimonial__card {
  padding: 25px;
  background-color: #fff;
  color: #333;

  border-radius: 25px;

  margin: 0 auto;

  width: 400px;
  height: fit-content;
}

.testimonial__card_header {
  display: flex;
  justify-content: space-between;
}

.testimonial__card_headerUser_profile {
  display: flex;
  gap: 10px;
  align-items: center;
}

.user_profile__image {
  background-color: #333;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.username {
  font-weight: 700;
}

.user_handle {
  font-size: 14px;
  margin-top: 5px;
  color: rgba(51, 51, 51, 0.651);
}

.beneficiary__tag {
  border: 1px solid orangered;
  border-radius: 50px;
  font-size: 11px;

  padding: 5px 10px;

  display: flex;
  align-self: center;
}

.testimony__content {
  margin: 20px 0;
  font-weight: 300;
  line-height: 1.5;
}

.testimony__footer {
  display: flex;
  justify-content: space-between;
}

.date {
  color: rgba(51, 51, 51, 0.63);
  font-size: 11px;
}

.platform {
  text-transform: uppercase;
  text-decoration: underline;
  color: blue;
  font-size: 11px;
}

.slick-prev:before,
.slick-next:before {
  opacity: 0;
}

@media screen and (max-width: 1024px) {
  .testimonial__card {
    width: 300px;
  }

  .testimony__content {
    font-size: 16px;
  }

  .username {
    font-size: 16px;
  }
}

@media screen and (max-width: 425px) {
  .testimonial__card {
    width: 323px;
  }
}
