.leaderboard {
  margin: 74px auto 0;
  padding-bottom: 200px;
  width: 100%;
  max-width: 1057px;
}

.header {
  text-align: center;
}

.tableContainer {
  width: 100%;
  margin: 0 auto;
  height: auto;
  gap: 0px;
  border-radius: 12px;
  border: 1px;
  box-shadow: 0px 0px 3px 0px #1018281a;
  border: 1px solid #e4e7ec;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background: white;
}

.table thead {
  width: 100%;
  background: #f9fafb;
  border-bottom: 1px solid #e4e7ec;
}

.table thead th {
  padding: 16px 8px;
  font-family: 'Manrope';
  font-size: 16spx;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #475467;
}

.table tbody td {
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #101828;
  padding: 12px 8px;
  border-bottom: 1px solid #e4e7ec;
}

.leftAlign {
  text-align: left !important;
}

.paginationContainer {
  width: 100%;
  padding-bottom: 40px;
}

.avatar {
  display: none;
}

.tabs {
  display: flex;
  width: 100%;
  margin-top: 44px;
  margin-bottom: 32px;
}

.tab {
  width: 50%;
  font-size: 14px;
  padding: 0;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: center;
  background: #e1e1e1;
  color: var(--edustipend-black);
  border-radius: 4px;
}

.activeTab {
  background: var(--edustipend-purple);
  color: var(--white);
}

@media only screen and (max-width: 768px) {
  .header {
    font-size: 32px;
    font-weight: 500;
    line-height: 35.2px;
  }
}

@media only screen and (min-width: 768px) {
  .leaderboard {
    margin: 84px auto 0;
  }
  .tabs {
    margin-top: 56px;
  }
  .tab {
    font-size: 20px;
  }
  .avatar {
    display: inline-flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 40px;
    font-size: 16px;
    margin-right: 12px;
    line-height: 24px;
    justify-content: center;
    align-items: center;
    background: #f9f5ff;
    font-family: 'Inter';
    font-weight: 500;
    color: #5801ff;
  }
  .table thead th {
    padding: 24px;
    font-family: 'Manrope';
    font-size: 20px;
  }
  .table tbody td {
    font-size: 20px;
    padding: 16px 24px;
  }
}
