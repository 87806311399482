.Main {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 80px;
}

.greet {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.dashboard {
  font-size: 12px;
  color: gray;
}

.hello {
  font-weight: 500;
  font-size: 20px;
}

.imgContainer {
  width: 20px;
  height: 20px;
}

.imgContainer1 {
  width: 15px;
  height: 15px;
}

.img {
  width: 100%;
}

.waveSection {
  display: flex;
  align-items: center;
}

.table {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-bottom: 50px;
}

.tabs {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.tab {
  width: 100%;
  font-size: 16px;
  background-color: var(--tab-btn);
  color: var(--edustipend-purple);
  font-weight: 700;
}

.tabAlt {
  background-color: var(--tab-btn-alt);
  opacity: 0.5;
  font-weight: 400;
  color: var(--navy-blue);
}

.buttonContainer,
.btnContainer {
  display: flex;
  justify-content: center;
}

.btnContainer {
  margin-top: -80px;
}

.quote {
  background: linear-gradient(90.41deg, #d54564 1.1%, #5801ff 96.69%);
  font-family: 'Manrope';
  padding: 2rem;
  border-radius: 30px;
  color: var(--white);
  text-align: center;
  display: flex;
  justify-content: center;
}

.quoteText {
  text-align: center;
  max-width: 800px;
  font-size: 16px;
  font-weight: 500;
}

.imgContainer {
  margin-left: 8px;
}

.italic {
  font-weight: 300;
}

.verifyBanner {
  margin: 1.5rem 0;
  background-color: var(--edustipend-yellow);
  font-family: 'Manrope';
  padding: 2rem;
  border-radius: 30px;
  color: var(--white);
  text-align: center;
  display: flex;
  justify-content: center;
}

.skeleton {
  animation: pulse 3.5s infinite;
  background: linear-gradient(-90deg, var(--table-head-bg) 25%, #fff 50%, var(--table-head-bg) 75%);
  background-size: 400% 100%;
  border-radius: 4px;
  display: inline-block;
  height: 75px;
  width: 100%;
}

@keyframes pulse {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@media screen and (min-width: 600px) {
  .tabs {
    flex-direction: row;
    gap: 30px;
  }

  .buttonContainer,
  .btnContainer {
    justify-content: end;
  }
}

@media screen and (min-width: 1024px) {
  .tab {
    width: 271px;
    height: 43px;
  }

  .dashboard {
    font-size: 20px;
  }

  .hello {
    font-size: 32px;
  }

  .imgContainer {
    width: 30px;
    height: 30px;
  }

  .imgContainer1 {
    margin-bottom: 1.5rem;
  }

  .quote {
    padding: 3rem;
    margin: 1.5rem 0;
  }

  .quoteText {
    font-size: 20px;
  }

  .menu {
    display: none;
  }
}
