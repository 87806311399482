.container {
  width: 100%;
  padding: 100px 0px 40px;
}

.wrap {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leftwrap h1 {
  font-size: 32px;
}

.desc {
  font-size: 12px;
  line-height: 24px;
  font-family: 'Manrope';
  width: 100%;
  color: var(--edustipend-black);
  font-weight: 400;
  margin-top: 10px;
}

.rightwrap {
  display: none;
}

.missvis {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 40px;
}
.mission {
  background-image: url('../../assets/about-bg.svg');
  background-size: cover;
  height: 400px;
  width: 100%;
  border-radius: 32px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.mission::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
}

.mission p {
  color: var(--white);
  z-index: 999;
}

.vision {
  background-image: url('../../assets/about-bg.svg');
  background-size: cover;
  height: 400px;
  width: 100%;
  border-radius: 32px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.vision::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
}

.vision p {
  color: var(--white);
  z-index: 999;
}

.mission h4 {
  font-size: 24px;
  color: var(--white);
  z-index: 999;
}
.vision h4 {
  font-size: 24px;
  color: var(--white);
  z-index: 999;
}

@media only screen and (min-width: 640px) {
  .mission {
    height: 350px;
  }

  .vision {
    height: 350px;
  }
}
@media only screen and (min-width: 800px) {
  .missvis {
    flex-direction: row;
  }
}

@media only screen and (min-width: 1024px) {
  .container {
    padding: 100px 70px 50px;
  }

  .wrap {
    padding-top: 50px;
  }

  .leftwrap h1 {
    font-size: 64px;
  }

  .desc {
    font-size: 24px;
    line-height: 37px;
    margin-top: 20px;
    width: 764px;
  }

  .missvis {
    flex-direction: row;
    gap: 40px;
  }

  .mission {
    height: 532px;
    justify-content: start;
    gap: 60px;
    padding: 50px 30px;
  }
  .vision {
    height: 532px;
    justify-content: start;
    gap: 60px;
    padding: 50px 30px;
  }

  .mission h4 {
    font-size: 32px;
  }
  .vision h4 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 1040px) {
  .mission h4 {
    font-size: 28px;
  }
  .vision h4 {
    font-size: 28px;
  }
  .rightwrap {
    display: flex;
    width: 100%;
    justify-content: end;
  }

  .mission {
    height: 450px;
  }
  .vision {
    height: 450px;
  }

  .mission {
    padding: 60px 40px;
  }
  .vision {
    padding: 60px 40px;
  }
}

@media only screen and (min-width: 1440px) {
  .mission h4 {
    font-size: 36px;
  }
  .vision h4 {
    font-size: 36px;
  }
}
