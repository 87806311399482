.dataConsent {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins';
  gap: 24px;
}

.headerSection {
  display: flex;
  align-items: center;
}

.handShake {
  width: 27px;
  height: 27px;
  margin-left: 0.5rem;
}

.header {
  font-size: 32px;
  color: var(--edustipend-black);
  font-weight: 700;
}

.text {
  font-family: 'Manrope';
  color: var(--edustipend-black);
  max-width: 620px;
  font-size: 16px;
}

.acknowledge {
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.label {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  max-width: 410px;
  font-size: 12px;
  font-family: 'Manrope';
  padding: 26px 20px;
  font-weight: 500;
  color: var(--edustipend-black);
  border: 1px solid var(--darker-navy-blue);
  border-radius: 16px;
}

.label:hover {
  cursor: pointer;
}

.radioInput {
  accent-color: var(--edustipend-purple);
  transform: scale(1.5);
}

.btnContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 26px;
}

.btn {
  max-width: 275px;
  color: var(--edustipend-black);
}

@media screen and (min-width: 425px) {
  .label {
    font-size: 14px;
  }
}

@media screen and (min-width: 500px) {
  .btnContainer {
    align-items: flex-end;
  }
}

@media screen and (min-width: 768px) {
  .dataConsent {
    gap: 32px;
  }

  .header {
    font-size: 36px;
  }

  .handShake {
    width: 40px;
    height: 40px;
  }

  .text {
    font-size: 18px;
  }

  .label {
    font-size: 16px;
  }

  .btnContainer {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 45px;
  }
}