.login {
  background: url('../../assets/hero-bg.png');
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0 12px;
}
