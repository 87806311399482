.desktopTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8em;
  border-radius: 30px;
  margin: 0;
  font-family: 'Manrope';
  box-shadow: 0px 9px 38px 0px var(--box-shadow);
}

.desktopTable thead th {
  text-align: left;
  border-bottom: 1px solid var(--line-alt);
  font-weight: 600;
  color: var(--edustipend-black);
}

.head {
  background-color: var(--table-head-bg);
}

.desktopTable thead th:first-child {
  border-top-left-radius: 30px;
}

.desktopTable thead th:last-child {
  border-top-right-radius: 30px;
}

.desktopTable th,
.desktopTable td {
  padding: 1rem 1.6rem;
  border-right: 1px solid var(--line-alt);
}

.desktopTable td {
  background-color: var(--white);
  font-weight: 400;
}

.desktopTable th:last-child,
.desktopTable td:last-child {
  border-right: 1px solid transparent;
}

.desktopTable tr:last-child {
  border-bottom-left-radius: 30px;
}

.desktopTable td {
  padding: 1.5rem;
}

.firstColumn {
  border-bottom-left-radius: 30px;
}

.lastColumn {
  border-bottom-right-radius: 30px;
}

.desktopTable tbody tr {
  border-bottom: 1px solid var(--line-alt);
}

.desktopTable tbody tr:last-child {
  border-bottom: 1px solid transparent;
}

.actionContainer {
  height: 38px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  border-radius: 10px;
}

.line {
  width: 1px;
  height: 100%;
  background: #0000001a;
}

.view {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  background: rgba(88, 0, 255, 0.05);
  padding: 8px;
}

.view p {
  color: var(--Edustipend-Purple, #5801ff);
  font-family: 'Manrope';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.edit {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  background: rgba(18, 183, 106, 0.05);
  padding: 8px;
}

.edit p {
  color: #039855;
  font-family: 'Manrope';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.edit:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn {
  margin: 0;
  padding: 0;
  font-size: 14px;
  width: 30px;
  height: 30px;
  transition: all 1s;
  background: white;
}

.content {
  position: absolute;
  top: -50px;
  left: -50px;
  display: block;
  width: 150px;
  background: white;
  z-index: 2;
  color: red;
  padding: 4px;
  font-size: 14px;
  box-shadow: 0px 0px 10px 0px rgba(88, 0, 255, 0.2);
  font-weight: 900;
}

.btn:hover {
  background: var(--table-head-bg);
  border-radius: 50%;
}

@media screen and (min-width: 1400px) {
  .desktopTable {
    font-size: 0.95em;
  }
}