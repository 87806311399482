.reset {
  background: url('../../assets/hero-bg.png');
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

.contentContainer {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 1.5rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background: var(--white);
  padding: 30px 20px;
  margin-top: 8rem;
  margin-bottom: 4rem;
  border-radius: 30px;
  width: 100%;
  max-width: 700px;
}

.header {
  font-size: 24px;
  text-align: center;
}

.fields {
  width: 100%;
  max-width: 500px;
}

.field {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.single {
  position: relative;
}

.small {
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  color: var(--denied);
}

.btnContainer {
  padding-top: 1.5rem;
}

.btn {
  max-width: 280px;
}

.error {
  margin-top: 1rem;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: var(--denied);
}

.contentContainer {
  margin-inline: 1.5rem;
  max-width: 856px;
  display: flex;
  align-items: center;
  min-height: 100vh;
}

.Modal {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  gap: 1rem;
  padding: 2rem 1rem;
}

.img {
  width: 65px;
  height: 65px;
}

.feedBack {
  text-align: center;
  max-width: 400px;
  font-size: 18px;
  font-weight: 600;
}

.btn {
  width: 240px;
}

@media screen and (max-width: 350px) {
  .header {
    font-size: 18px;
  }
}

@media screen and (min-width: 375px) {
  .btn {
    width: 248px;
  }

  .content {
    padding: 38px 30px;
  }
}

@media screen and (min-width: 425px) {
  .btn {
    width: 310px;
  }
}

@media screen and (min-width: 500px) {
  .content {
    padding-inline: 30px;
  }
}

@media screen and (min-width: 426px) {
  .btnContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .content {
    gap: 3rem;
  }

  .header {
    font-size: 32px;
  }

  .field {
    gap: 1.5rem;
  }

  .btnContainer {
    padding: 0;
  }
}

@media screen and (min-width: 768px) {
  .contentContainer {
    margin-inline: auto;
  }

  .img {
    width: 96px;
    height: 96px;
  }

  .Modal {
    gap: 2rem;
    padding: 3rem;
  }

  .feedBack {
    font-size: 24px;
  }
}
